import { inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '@app/constants';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import { environment } from '@environment/environment';
import { NavController } from '@ionic/angular/standalone';
import { Participant } from '@model/participant';
import { Ad6Notification, PushType } from '@model/push-notification';
import { AppDataService } from '@service/app-data.service';
import { StorageService } from '@service/storage.service';

@Injectable({ providedIn: 'root' })
export class PushService {
  private appDataService = inject(AppDataService);
  private navController = inject(NavController);
  private router = inject(Router);
  private storageService = inject(StorageService);
  appSettings = this.appDataService.appSettings;

  favorites = this.appDataService.favorites;
  isPushAvailable = false;
  loading = false;
  newsNotifications = this.appDataService.newsNotifications;

  skipNavigateToRootOnce = false;
  unreadNotifications = this.appDataService.unreadNewsNotifications;
  private ngZone = inject(NgZone);


  private addListeners(): void {
    PushNotifications.addListener('registration', async (token: Token) => {
      console.log('Registered for push with token: ', token.value);
      this.appDataService.setPushToken(token.value);
    });

    PushNotifications.addListener('registrationError', async (error: unknown) => {
      console.log('Error:', error);
      this.appDataService.setPushToken(null);
    });

    // Method called when a notification is received
    PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
      console.log('pushNotificationReceived', notification);
      await this.processStoredNotifications();
    });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', async (notificationAction: ActionPerformed) => {
      console.log('pushNotificationActionPerformed', notificationAction);
      await this.processPushNotificationAction(notificationAction);
    });
  }

  private async processPushNotificationAction(pushNotificationAction: ActionPerformed): Promise<void> {
    this.loading = true;
    this.skipNavigateToRootOnce = true;

    await this.processStoredNotifications();

    const notification = new Ad6Notification(pushNotificationAction.notification.data);
    console.log('processPushNotificationAction:', notification);
    if (!notification) {
      this.loading = false;

      return;
    }

    if (notification.type === PushType.LAP) {
      this.ngZone.run(() => {
        this.navController.navigateForward([
          'tabs',
          'live',
        ], { animated: true }).then(() => this.navController.navigateForward([
          '/tabs/live/participant',
          notification.participantUuid,
        ], { animated: true }));
      });
    } else {
      this.ngZone.run(() => {
        this.navController.navigateForward([
          'tabs',
          'more',
        ], { animated: true })
          .then(() => this.navController.navigateForward(['/tabs/more/notifications'], { animated: true }))
          .then(() => this.loading = false);
      });
    }
  }

  private async subscribeTo(topic: string): Promise<void> {
    return FCM.subscribeTo({ topic })
      .then(success => {
        console.log(`FCM.subscribeTo('${topic}') success!`, success);
      })
      .catch(fail => {
        console.error(`FCM.subscribeTo('${topic}') failed!`, fail);
      });
  }

  private async unsubscribeFrom(topic: string): Promise<void> {
    return FCM.unsubscribeFrom({ topic })
      .then(success => {
        console.log(`FCM.unsubscribeFrom('${topic}') success!`, success);
      })
      .catch(fail => {
        console.error(`FCM.unsubscribeFrom('${topic}') failed!`, fail);
      });
  }

  async initialize(): Promise<void> {
    const platform = Capacitor.getPlatform();
    const isNative = Capacitor.isNativePlatform();

    this.isPushAvailable = platform === 'android' || (platform === 'ios' && isNative);

    console.log('Initialized PushService');
  }

  async processStoredNotifications(notification: Ad6Notification = null): Promise<void> {
    const storedNotifications = await this.storageService.getStoredNotifications(Constants.notificationStorageKey) || [];

    if (storedNotifications.length === 0 && notification) {
      storedNotifications.push(notification);
    }
    if (storedNotifications.length === 0) {
      return;
    }

    const currentUrl = this.router.routerState.snapshot.url;
    storedNotifications.forEach(async (n: Ad6Notification) => {
      if (n.type === PushType.NEWS) {
        await this.saveNewsNotification(n);
      } else if (n.type === PushType.LAP) {
        if (currentUrl.indexOf(n.participantUuid) < 0) {
          this.appDataService.addUnreadPassageForFavorite(n);
        }
      }
    });
    this.storageService.clearKey(Constants.notificationStorageKey);

    return;
  }

  async register(): Promise<PermissionState> {
    if (this.isPushAvailable && (this.appSettings().pushOnNews || this.appSettings().pushOnFavorite)) {
      let permissionStatus = await PushNotifications.checkPermissions();

      if (permissionStatus.receive === 'prompt' || permissionStatus.receive === 'prompt-with-rationale') {
        permissionStatus = await PushNotifications.requestPermissions();
      }

      console.log('`PushService - permissionStatus = ', permissionStatus);

      if (permissionStatus.receive === 'granted') {
        PushNotifications.removeAllListeners();
        this.addListeners();
        await PushNotifications.register();

        if (this.appSettings().pushOnFavorite) {
          this.subscribeToAllFavorites();
        } else {
          this.unsubscribeFromAllFavorites();
        }

        if (this.appSettings().pushOnNews) {
          this.subscribeToNews(this.appSettings().pushTopicNews);
        } else {
          this.unsubscribeFromNews(this.appSettings().pushTopicNews);
        }
      }

      else if (permissionStatus.receive === 'denied') {
        PushNotifications.removeAllListeners();
        this.unsubscribeFromNews(this.appSettings().pushTopicNews);
        await this.appDataService.togglePushOnNews(false);
        this.unsubscribeFromAllFavorites();
        await this.appDataService.togglePushOnFavorite(false);
      }

      return permissionStatus.receive as PermissionState;
    } else {
      console.log('No push needed');
    }

    return null;
  }

  async saveNewsNotification(notification: Ad6Notification): Promise<void> {
    if (!this.isPushAvailable || !notification) {
      return;
    }

    this.appDataService.addNewsNotifications([new Ad6Notification(notification)]);
  }

  subscribeToAllFavorites(): void {
    if (this.isPushAvailable) {
      this.appDataService.favorites()
        .forEach(participant => {
          this.subscribeTo(`${this.appSettings().pushTopicParticipantPrefix}${participant.uuid}`);
        });
    }
  }

  subscribeToFavorite(participant: Participant): Promise<void> {
    if (this.isPushAvailable) {
      return this.subscribeTo(`${this.appSettings().pushTopicParticipantPrefix}${participant.uuid}`);
    }

    return Promise.resolve(null);
  }

  subscribeToNews(topic: string): void {
    if (this.isPushAvailable) {
      if (!environment.production) {
        // this.subscribeTo('TEST.AD6.2024.johanb');
      }
      this.subscribeTo(topic);
    }
  }

  async togglePushSetting(type: PushType, value?: boolean): Promise<boolean> {

    if (type === PushType.NEWS) {
      return await this.appDataService.togglePushOnNews(value);
    }
    if (type === PushType.LAP) {
      return await this.appDataService.togglePushOnFavorite(value);
    }
  }

  async unregister(): Promise<void> {
    if (this.isPushAvailable) {
      await PushNotifications.unregister();
      await PushNotifications.removeAllListeners();
      await this.appDataService.setPushToken(null);
      await this.appDataService.togglePushOnNews(false);
      await this.appDataService.togglePushOnFavorite(false);
      this.unsubscribeFromNews(this.appSettings().pushTopicNews);
      this.unsubscribeFromAllFavorites();
    }
  }

  unsubscribeFromAllFavorites(): void {
    if (this.isPushAvailable) {
      this.appDataService.favorites().forEach(participant => {
        this.unsubscribeFrom(`${this.appSettings().pushTopicParticipantPrefix}${participant.uuid}`);
      });
    }
  }

  unsubscribeFromFavorite(participant: Participant): void {
    if (this.isPushAvailable) {
      this.unsubscribeFrom(`${this.appSettings().pushTopicParticipantPrefix}${participant.uuid}`);
    }
  }

  unsubscribeFromNews(topic: string): void {
    if (this.isPushAvailable) {
      if (!environment.production) {
        // this.unsubscribeFrom('TEST.AD6.2024.johanb');
      }
      this.unsubscribeFrom(topic);
    }
  }

}
